import { Button, Dialog, DialogContent, IconButton, Stack, TextField } from '@mui/material';
import { Delete, Cancel, Warning, CheckCircle } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import BetatesterService from '../../services/betatesterService';
import Title from '../Title';
import DividerTitle from '../DiviverTitle';
import { useEffect } from 'react';
import Paragraph from '../Paragraph';
import { PayloadProfile } from '../../utils/decodeToken';
import { maskCpf } from '../../utils/maskCpf';
import { toast } from 'react-toastify';
import secureStorage from 'react-secure-storage';
import useBetaTesters from '../../contexts/useBetaTestersContext';

interface MyAccountMobileModalProps {
	myAccountMobileModalOpen: boolean;
	setMyAccountMobileModalOpen: (value: boolean) => void;
	setDeleteAccountMobileModalOpen: (value: boolean) => void;
}

interface IMyAccountFields {
	nr_cpf: string;
	dt_birth: string;
	txt_name: string;
	txt_email: string;
}

const MyAccountMobileModal = ({
	myAccountMobileModalOpen,
	setMyAccountMobileModalOpen,
	setDeleteAccountMobileModalOpen
}: MyAccountMobileModalProps) => {
	const { nr_cpf, dt_birth, txt_name, txt_email, txt_question_status } = secureStorage.getItem(
		'userProfile'
	) as PayloadProfile;

	const formatedDate = new Date(dt_birth).toISOString().split('T');

	const { control, handleSubmit } = useForm<IMyAccountFields>({
		defaultValues: {
			nr_cpf: maskCpf(String(nr_cpf)),
			dt_birth: formatedDate[0],
			txt_name: txt_name,
			txt_email: txt_email
		}
	});

	const mutation = useMutation({
		mutationFn: BetatesterService.updateAccount,
		onSuccess: (data: PayloadProfile) => {
			toast('Dados atualizados com sucesso!', { type: 'success' });
			const userProfile = secureStorage.getItem('userProfile') as PayloadProfile;
			const newProfile: PayloadProfile = { ...userProfile, txt_email: data.txt_email };

			secureStorage.setItem('userProfile', newProfile);
		}
	});

	const onSubmit = ({ txt_email }: IMyAccountFields) => {
		mutation.mutate({ txt_email });
	};

	const { setQuestionMobileModalOpen } = useBetaTesters();

	useEffect(() => {
		return () => setMyAccountMobileModalOpen(false);
	}, []);

	return (
		<Dialog open={myAccountMobileModalOpen} fullScreen>
			<DialogContent>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
						<div style={{ marginBottom: '24px' }}>
							<div>
								<Title fontSize="28px" color="#465EFF">
									Sua Conta
								</Title>
								<DividerTitle marginBottom={false} />
							</div>
						</div>
						<IconButton onClick={() => setMyAccountMobileModalOpen(false)} sx={{ marginLeft: '20px' }}>
							<Cancel sx={{ color: '#888D95' }} />
						</IconButton>
					</div>
					{txt_question_status === 'IN_RE' || txt_question_status === 'IN' ? (
						<div
							style={{
								display: 'flex',
								padding: '10px',
								backgroundColor: '#FEFAE9',
								borderBottom: '4px solid #E3A702',
								borderRadius: '5px 5px 0 0',
								margin: '24px 0'
							}}
						>
							<div style={{ marginRight: '10px' }}>
								<Warning sx={{ color: '#E3A702' }} />
							</div>
							<div>
								<Paragraph fontSize="16px" style={{ color: '#111214' }}>
									Questionário de perfil está incompleto.
								</Paragraph>
								<Button
									onClick={() => {
										setMyAccountMobileModalOpen(false);
										setQuestionMobileModalOpen(true);
									}}
									sx={{ color: '#3354FD', padding: 0, fontFamily: 'bb-text-medium', fontSize: '16px' }}
								>
									Preencher
								</Button>
							</div>
						</div>
					) : (
						txt_question_status === 'CP' && (
							<div
								style={{
									display: 'flex',
									padding: '10px',
									backgroundColor: '#EDFFF9',
									borderBottom: '4px solid #00907F',
									borderRadius: '5px 5px 0 0',
									margin: '24px 0'
								}}
							>
								<div style={{ marginRight: '10px' }}>
									<CheckCircle sx={{ color: '#00907F' }} />
								</div>
								<div>
									<Paragraph fontSize="16px" style={{ color: '#111214' }}>
										Questionário de perfil está atualizado.
									</Paragraph>
									<Button
										onClick={() => {
											setMyAccountMobileModalOpen(false);
											setQuestionMobileModalOpen(true);
										}}
										sx={{ color: '#3354FD', padding: 0, fontFamily: 'bb-text-medium', fontSize: '16px' }}
									>
										Refazer
									</Button>
								</div>
							</div>
						)
					)}
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack gap="24px">
							<Controller
								name="nr_cpf"
								control={control}
								rules={{ required: true, pattern: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/ }}
								render={({ field: { name, value, onChange }, fieldState: { error } }) => {
									return (
										<TextField
											name={name}
											value={value}
											onChange={onChange}
											type="text"
											label="CPF"
											error={!!error}
											helperText={error && 'CPF inválido.'}
											placeholder="000.000.000-00"
											variant="filled"
											sx={{ color: '#F0F2F4' }}
											fullWidth
											disabled={true}
										/>
									);
								}}
							/>
							<Controller
								name="txt_name"
								control={control}
								render={({ field: { name, value, onChange }, fieldState: { error } }) => {
									return (
										<TextField
											name={name}
											value={value}
											onChange={onChange}
											disabled={true}
											type="text"
											label="Nome"
											error={!!error}
											placeholder="José Souza Ramos"
											variant="filled"
											sx={{ color: '#F0F2F4' }}
											fullWidth
										/>
									);
								}}
							/>

							<Controller
								name="dt_birth"
								control={control}
								render={({ field: { name, value, onChange }, fieldState: { error } }) => {
									return (
										<TextField
											name={name}
											value={value}
											onChange={onChange}
											disabled={true}
											type="date"
											label="Data de Nascimento"
											error={!!error}
											placeholder="dd/mm/aaaa"
											variant="filled"
											sx={{ color: '#F0F2F4' }}
											fullWidth
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							/>
							<Controller
								name="txt_email"
								control={control}
								render={({ field: { name, value, onChange }, fieldState: { error } }) => {
									return (
										<TextField
											name={name}
											value={value}
											onChange={onChange}
											type="email"
											label="Email"
											error={!!error}
											placeholder="exemplo@email.com.br"
											variant="filled"
											sx={{ color: '#F0F2F4' }}
											fullWidth
											InputLabelProps={{ shrink: true }}
										/>
									);
								}}
							/>
						</Stack>
						<Button
							onClick={() => setDeleteAccountMobileModalOpen(true)}
							sx={{
								fontFamily: 'bb-text-medium',
								fontSize: '16px',
								textTransform: 'none',
								color: '#DF5B54',
								marginTop: '24px'
							}}
							startIcon={<Delete sx={{ color: '#DF5B54' }} />}
						>
							Excluir meu cadastro
						</Button>
						<Button
							type="submit"
							variant="contained"
							fullWidth
							sx={{
								fontFamily: 'bb-title-bold',
								fontSize: '16px',
								color: '#3354FD',
								marginTop: '32px',
								backgroundColor: '#FDF429',
								'&:hover': {
									backgroundColor: '#FDF429'
								}
							}}
						>
							SALVAR DADOS
						</Button>
					</form>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default MyAccountMobileModal;
