import {
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	RadioGroup,
	Radio,
	SelectChangeEvent
} from '@mui/material';
import Title from '../Title';
import Paragraph from '../Paragraph';
import { LegacyRef } from 'react';

type AnswerType = {
	id: number;
	id_question: number;
	txt_answer: string;
};

interface IQuestion {
	id: number;
	nr_order: number;
	txt_question: string;
	txt_aux?: string;
	tp_input: 'CH' | 'SE' | 'RA';
	txt_answers: AnswerType[];
}

type InputFields = {
	[key: string]: string | string[];
};

interface IQuestionInputProps {
	question: IQuestion;
	questionRefs: LegacyRef<HTMLDivElement>;
	setAllfields: (value: InputFields) => void;
	allFields: InputFields;
}

const QuestionInput = ({ question, questionRefs, setAllfields, allFields }: IQuestionInputProps) => {
	const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>, questionId: string) => {
		setAllfields({ ...allFields, [questionId]: event.target.value });
	};

	const onChangeSelect = (event: SelectChangeEvent<string | string[]>, questionId: string) => {
		setAllfields({ ...allFields, [questionId]: event.target.value });
	};

	const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, questionId: string) => {
		let checkboxeIds = (allFields[questionId] as Array<string>) || [];

		const { value, checked } = event.target;

		if (checked) {
			checkboxeIds = [...checkboxeIds, value];
		} else {
			checkboxeIds = checkboxeIds.filter(option => option !== value);
		}

		setAllfields({ ...allFields, [questionId]: checkboxeIds });
	};

	switch (question.tp_input) {
		case 'CH':
			return (
				<div ref={questionRefs}>
					<Title fontSize="22px" color="#111214" style={{ lineHeight: '27px' }}>
						{question.txt_question}
					</Title>
					<Paragraph style={{ lineHeight: '22px', marginTop: '8px' }}>{question.txt_aux}</Paragraph>
					<FormGroup
						sx={{
							marginTop: '24px',
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							rowGap: '16px',
							columnGap: '24px'
						}}
					>
						{question.txt_answers.map(item => {
							return (
								<FormControlLabel
									key={`item-${item.id}`}
									sx={{ border: '1px solid #B4B9C1', margin: 0, borderRadius: '4px' }}
									control={
										<Checkbox
											checked={
												Array.isArray(allFields[question.id]) &&
												(allFields[question.id] as Array<string>).includes(String(item.id))
											}
											value={item.id}
											onChange={event => onChangeCheckbox(event, String(question.id))}
										/>
									}
									label={item.txt_answer}
								/>
							);
						})}
					</FormGroup>
				</div>
			);

		case 'SE':
			return (
				<div ref={questionRefs}>
					<Title fontSize="22px" color="#111214" style={{ lineHeight: '27px' }}>
						{question.txt_question}
					</Title>
					<Paragraph style={{ lineHeight: '22px', marginTop: '8px' }}>{question.txt_aux}</Paragraph>
					<FormControl sx={{ minWidth: 200, marginTop: '24px' }}>
						<InputLabel>Selecione</InputLabel>
						<Select
							value={allFields[question.id] || ''}
							label="Selecione"
							onChange={event => onChangeSelect(event, String(question.id))}
						>
							{question.txt_answers.map(item => {
								return (
									<MenuItem key={`item-${item.id}`} value={String(item.id)}>
										{item.txt_answer}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
			);

		default:
			return (
				<div ref={questionRefs}>
					<Title fontSize="22px" color="#111214" style={{ lineHeight: '27px' }}>
						{question.txt_question}
					</Title>
					<Paragraph style={{ lineHeight: '22px', marginTop: '8px' }}>{question.txt_aux}</Paragraph>
					<FormControl sx={{ width: '100%', marginTop: '24px' }}>
						<RadioGroup
							value={allFields[question.id] || ''}
							onChange={event => onChangeRadio(event, String(question.id))}
						>
							{question.txt_answers.map(item => {
								return (
									<FormControlLabel
										key={`item-${item.id}`}
										value={item.id}
										control={<Radio />}
										label={item.txt_answer}
										sx={{ border: '1px solid #B4B9C1', margin: '0 0 16px 0', borderRadius: '4px' }}
									/>
								);
							})}
						</RadioGroup>
					</FormControl>
				</div>
			);
	}
};

export default QuestionInput;
