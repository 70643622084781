import { Button, Dialog, DialogContent, FormControlLabel, Grid, IconButton, TextField, Checkbox } from '@mui/material';
import { Delete, Cancel } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BetatesterService from '../../services/betatesterService';
import MyAccountIllustration from '../../assets/images/svgs/my-account.svg';
import Title from '../Title';
import DividerTitle from '../DiviverTitle';
import { useEffect, useState } from 'react';
import Paragraph from '../Paragraph';
import { PayloadProfile } from '../../utils/decodeToken';
import { maskCpf } from '../../utils/maskCpf';
import { toast } from 'react-toastify';
import secureStorage from 'react-secure-storage';
import useBetaTesters from '../../contexts/useBetaTestersContext';
import { OkProfileIcon, WarningProfileIcon } from '../../assets/images/images';

interface MyAccountModalProps {
	myAccountModalOpen: boolean;
	setAccountModalOpen: (value: boolean) => void;
	setDeleteAccountModalOpen: (value: boolean) => void;
	setTermModal: (value: boolean) => void;
}

interface IMyAccountFields {
	nr_cpf: string;
	dt_birth: string;
	txt_name: string;
	txt_email: string;
}

const MyAccountModal = ({
	myAccountModalOpen,
	setAccountModalOpen,
	setDeleteAccountModalOpen,
	setTermModal
}: MyAccountModalProps) => {
	const [checkBox, setCheckBox] = useState(true);

	const { nr_cpf, dt_birth, txt_name, txt_email } = secureStorage.getItem('userProfile') as PayloadProfile;

	const formatedDate = new Date(dt_birth).toISOString().split('T');

	const { control, handleSubmit } = useForm<IMyAccountFields>({
		defaultValues: {
			nr_cpf: maskCpf(String(nr_cpf)),
			dt_birth: formatedDate[0],
			txt_name: txt_name,
			txt_email: txt_email
		}
	});

	const mutation = useMutation({
		mutationFn: BetatesterService.updateAccount,
		onSuccess: (data: PayloadProfile) => {
			toast('Dados atualizados com sucesso!', { type: 'success' });
			const userProfile = secureStorage.getItem('userProfile') as PayloadProfile;
			const newProfile: PayloadProfile = { ...userProfile, txt_email: data.txt_email };

			secureStorage.setItem('userProfile', newProfile);
		}
	});

	const onSubmit = ({ txt_email }: IMyAccountFields) => {
		mutation.mutate({ txt_email });
	};

	const navigate = useNavigate();

	const { questionStatus } = useBetaTesters();

	useEffect(() => {
		return () => {
			setAccountModalOpen(false);
			setCheckBox(true);
		};
	}, []);

	return (
		<Dialog open={myAccountModalOpen} maxWidth="md">
			<DialogContent>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
							<img src={MyAccountIllustration} alt="Avatar usuário" />
							<div style={{ marginLeft: '24px' }}>
								<Title fontSize="28px" color="#465EFF">
									Sua Conta
								</Title>
								<DividerTitle marginBottom={false} />
							</div>
						</div>
						<IconButton onClick={() => setAccountModalOpen(false)} sx={{ marginLeft: '20px' }}>
							<Cancel sx={{ color: '#888D95' }} />
						</IconButton>
					</div>
					{questionStatus === 'IN_RE' || questionStatus === 'IN' ? (
						<div
							style={{
								display: 'flex',
								padding: '10px',
								backgroundColor: '#FEFAE9',
								borderBottom: '4px solid #E3A702',
								borderRadius: '5px 5px 0 0',
								margin: '24px 0'
							}}
						>
							<div style={{ marginRight: '10px' }}>
								<img src={WarningProfileIcon} alt="Aviso de perfil" />
							</div>
							<div>
								<Paragraph fontSize="16px" style={{ color: '#111214' }}>
									Questionário de perfil está incompleto.
								</Paragraph>
								<Button
									onClick={() => {
										navigate('/perguntas');
										setAccountModalOpen(false);
									}}
									sx={{ color: '#3354FD', padding: 0, fontFamily: 'bb-text-medium', fontSize: '16px' }}
								>
									Preencher
								</Button>
							</div>
						</div>
					) : (
						questionStatus === 'CP' && (
							<div
								style={{
									display: 'flex',
									padding: '10px',
									backgroundColor: '#EDFFF9',
									borderBottom: '4px solid #00907F',
									borderRadius: '5px 5px 0 0',
									margin: '24px 0'
								}}
							>
								<div style={{ marginRight: '10px' }}>
									<img src={OkProfileIcon} alt="Icone de perfil atualizado" />
								</div>
								<div>
									<Paragraph fontSize="16px" style={{ color: '#111214' }}>
										Questionário de perfil está atualizado.
									</Paragraph>
									<Button
										onClick={() => {
											navigate('/perguntas');
											setAccountModalOpen(false);
										}}
										sx={{ color: '#3354FD', padding: 0, fontFamily: 'bb-text-medium', fontSize: '16px' }}
									>
										Refazer
									</Button>
								</div>
							</div>
						)
					)}
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Controller
									name="nr_cpf"
									control={control}
									rules={{ required: true, pattern: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/ }}
									render={({ field: { name, value, onChange }, fieldState: { error } }) => {
										return (
											<TextField
												name={name}
												value={value}
												onChange={onChange}
												type="text"
												label="CPF"
												error={!!error}
												helperText={error && 'CPF inválido.'}
												placeholder="000.000.000-00"
												variant="filled"
												sx={{ color: '#F0F2F4' }}
												fullWidth
												disabled={true}
											/>
										);
									}}
								/>
								<Controller
									name="txt_name"
									control={control}
									render={({ field: { name, value, onChange }, fieldState: { error } }) => {
										return (
											<TextField
												name={name}
												value={value}
												onChange={onChange}
												disabled={true}
												type="text"
												label="Nome"
												error={!!error}
												placeholder="José Souza Ramos"
												variant="filled"
												sx={{ color: '#F0F2F4', marginTop: '24px' }}
												fullWidth
											/>
										);
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Controller
									name="dt_birth"
									control={control}
									render={({ field: { name, value, onChange }, fieldState: { error } }) => {
										return (
											<TextField
												name={name}
												value={value}
												onChange={onChange}
												disabled={true}
												type="date"
												label="Data de Nascimento"
												error={!!error}
												placeholder="dd/mm/aaaa"
												variant="filled"
												sx={{ color: '#F0F2F4' }}
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										);
									}}
								/>
								<Controller
									name="txt_email"
									control={control}
									render={({ field: { name, value, onChange }, fieldState: { error } }) => {
										return (
											<TextField
												name={name}
												value={value}
												onChange={onChange}
												type="email"
												label="Email"
												error={!!error}
												placeholder="exemplo@email.com.br"
												variant="filled"
												sx={{ color: '#F0F2F4', marginTop: '24px' }}
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										);
									}}
								/>
							</Grid>
						</Grid>
						<FormControlLabel
							sx={{ marginTop: '32px' }}
							label={
								<p style={{ fontFamily: 'bb-text-regular', fontSize: '16px', color: '#111214', lineHeight: '22px' }}>
									Eu concordo com a{' '}
									<a
										style={{ color: '#111214' }}
										href="https://www.bbseguros.com.br/politica-de-privacidade"
										target="_blank"
									>
										<u>Política de Privacidade</u>
									</a>{' '}
									e permito enviar meus dados para a BB Seguros e coligadas.
								</p>
							}
							control={
								<Checkbox
									checked={checkBox}
									defaultChecked
									onChange={event => {
										if (!event.target.checked) {
											setTermModal(true);
											setCheckBox(true);
										}
									}}
								/>
							}
						/>

						<Button
							onClick={() => setDeleteAccountModalOpen(true)}
							sx={{
								fontFamily: 'bb-text-medium',
								fontSize: '16px',
								textTransform: 'none',
								color: '#DF5B54',
								marginTop: '24px'
							}}
							startIcon={<Delete sx={{ color: '#DF5B54' }} />}
						>
							Excluir meu cadastro
						</Button>
						<div style={{ width: '100%', border: '1px solid #E5E7EB', margin: '24px 0' }}></div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button
								onClick={() => setAccountModalOpen(false)}
								variant="contained"
								sx={{
									fontFamily: 'bb-title-bold',
									fontSize: '16px',
									color: '#3354FD',
									backgroundColor: '#E4ECFF',
									'&:hover': {
										backgroundColor: '#E4ECFF'
									}
								}}
							>
								CANCELAR
							</Button>
							<Button
								type="submit"
								variant="contained"
								sx={{
									fontFamily: 'bb-title-bold',
									fontSize: '16px',
									color: '#3354FD',
									backgroundColor: '#FDF429',
									'&:hover': {
										backgroundColor: '#FDF429'
									}
								}}
							>
								SALVAR DADOS
							</Button>
						</div>
					</form>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default MyAccountModal;
